import { React } from 'react'
import Layout from '../components/Layout/indexWp'
import SEO from '../components/SEO'
import styled from '@emotion/styled'

const StyledIframe = styled.iframe`
  height: 100%;
  overflow-y:scroll !important;
  -webkit-overflow-scrolling: touch !important;
`

const Project = ({ data }) => {
  return (
    <Layout>
      <SEO title="Joyful Church Renovation Project | 6111 Johnson Ct, Pleasanton, CA | 죠이플 교회" description="Learn about the Joyful Church renovation at 6111 Johnson Ct, Pleasanton, CA."  />
          <StyledIframe 
            log
            src="https://wp.joyfulchurch.us/project/" 
            style={{ width: '100%', minWidth: '100%'}}
          />
    </Layout>
  )
}

export default Project
