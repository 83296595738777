import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import moment from 'moment-timezone'
import ReactPlayer from 'react-player/lazy'
import { Link, graphql, StaticQuery } from 'gatsby'

const PageContainer = styled.div`
  background: linear-gradient(45deg, #f7f3ec 0%, #e3f7ff 100%);
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.5rem;
  min-height: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 0;
  }
`
const MainServiceContainer = styled.section`
  margin: 0 auto;
  display: flex;
  padding: 4rem;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 2rem;
  }
`
const TitleDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-bottom: 3rem;
  min-width: 58rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    min-width: initial;
  }
`
const Title = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 1.5rem 0;
  }
`
const MainServiceDescription = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  word-break: keep-all;
`
const MainServiceLinks = styled(Link)`
  font-family: 'Apple SD Gothic Neo';
  color: ${props => props.theme.colors.green};
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5rem;
  text-decoration: none;
`
const ServiceDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
`
const ServiceDetailVideoContainer = styled.div`
  display: flex;
  width: 38rem;
  margin-right: 2rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 100%;
    margin-right: initial;
  }
`
const DescriptionDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 100%;
  }
`
const DescriptionDetailTitle = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 1.5rem;
  margin-top: 0.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
`
const DescriptionDetailTitleMobile = styled.div`
  display: none;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 1.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: flex;
  }
`
const TimeRemaingingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 6rem;
  width: 100%;
  border: 2px solid ${props => props.theme.colors.text};
  border-radius: 1px;
  background-color: ${props => props.theme.colors.background};
`
const TimeRemainingTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0.5rem auto 0.5rem;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
`
const TimeRemaing = styled.div`
display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 0.5rem;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
  align-items: baseline;
`
const TimeUp = styled.div`
  height: 100%;
  width: 100%;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
`
const DescriptionDetailLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1.25rem auto 1.5rem;
`
const DescriptionDetailLinksExternal = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${props => props.theme.colors.green};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.5rem;
  @media screen and (max-width: 24rem) {
    font-size: 0.9rem;
    line-height: 1rem;
  }
`
const DescriptionDetailLinks = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${props => props.theme.colors.green};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.5rem;
  @media screen and (max-width: 24rem) {
    font-size: 0.9rem;
    line-height: 1rem;
  }
`
const OnlineDonation = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
  word-break: keep-all;
`
const OnlineDonationLink = styled(Link)`
  width: 100%;
  color: #2f3133;
  font-family: 'Apple SD Gothic Neo';
  background: linear-gradient(to top, #fdb51e 50%, transparent 50%);
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
`
const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`
const PlayerWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 */
`
const TextLinkExternal = styled.a`
  /* border: 1px dotted #ccc; */
  margin-right: auto;
  text-decoration: none;
  color: ${props => props.theme.colors[props.fontColor]};
  font-weight: 600;
`
const remainingFontSize = { fontSize: '1.5rem', width: '2rem', textAlign: 'end' }
const remainingUnitFontSize = { fontSize: '1rem', width: '2rem', textAlign: 'center' }
const DescriptionTitleFont = { fontWeight: 'bold', marginRight: '0.75rem' }

// Constants
const secondsPerDay = 86400
const secondsPerHour = 3600
const secondsPerMinute = 60

// Calculate time remaining
function calculateTimeRemaining() {
  const sundayDOW = 7
  const timeZone = 'America/Los_Angeles'
  const timeFormat = 'MM DD YYYY, h:mm a'
  const now = moment().tz(timeZone)
  const anySundayService = moment()
    .isoWeekday(sundayDOW)
    .set('hour', 4)
    .set('minute', 0)
    .set('second', 0)

  let upcomingSundayService =
    now <= anySundayService
      ? moment()
          .isoWeekday(sundayDOW)
          .set('hour', 13)
          .set('minute', 30)
          .set('second', 0)
      : moment()
          .add(1, 'weeks')
          .isoWeekday(sundayDOW)
          .set('hour', 13)
          .set('minute', 30)
          .set('second', 0)
  let upcomingSundayServiceFormatted = moment.tz(
    upcomingSundayService,
    timeFormat,
    timeZone
  )

  let duration = moment.duration(upcomingSundayServiceFormatted.diff(now))
  return parseInt(duration.asSeconds())
}

// Add a leading zero for hours:minutes:seconds
function addZero(num) {
  return ('0' + num).slice(-2)
}

// Convert seconds to days:hours:minutes:seconds
function secondsToTime(seconds) {
  /*
  const secondsPerDay = 86400
  const secondsPerHour = 3600
  const secondsPerMinute = 60
  */

  let time = {}
  time.days =
    seconds >= secondsPerDay ? Math.floor(seconds / secondsPerDay) : null
  seconds = seconds % secondsPerDay
  time.hours =
    seconds >= secondsPerHour
      ? addZero(Math.floor(seconds / secondsPerHour))
      : time.days
      ? '00'
      : null
  seconds = seconds % secondsPerHour
  time.minutes =
    seconds >= secondsPerMinute
      ? addZero(Math.floor(seconds / secondsPerMinute))
      : time.hours
      ? '00'
      : null
  seconds = seconds % secondsPerMinute
  time.seconds = addZero(seconds)
  return time
}

// Custom hook for counter (https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const OnlineService = () => {
  const [seconds, setSeconds] = useState(calculateTimeRemaining())
  const [displayBox, setDisplayBox] = useState(false)
  const [displayCounter, setDisplayCounter] = useState(true)

  useInterval(() => {
    setSeconds(seconds - 1)
    // Only show counter box when the amount of time to the next service is >15 minutes remaining AND <6 days away
    // (I.e. counter box remains hidden between 10:45AM on the day of _and_ after the following Monday 11AM)
    if (seconds < 6 * secondsPerDay && seconds > 15 * secondsPerMinute) {
      setDisplayBox(true)
    }
  }, 1000)

  useEffect(() => {
    // Re-render the counter 15 minutes prior to the service to show the post-counter message unless page is refreshed
    if (seconds === 15 * secondsPerMinute) {
      setDisplayCounter(false)
    }
  }, [seconds])

  const formattedTime = secondsToTime(seconds)

  return (
    <StaticQuery
      query={graphql`
        query{
          allContentfulWeeklySermonUpdate {
            edges {
              node {
                upcomingSermonVideoId
              }
            }
          }
        }
      `}
      render={data=> {
        const { upcomingSermonVideoId } = data.allContentfulWeeklySermonUpdate.edges[0].node || `/channel/UCbQOWfiPqD-JLQzWmAkQ_DQ`;
        return (
          <PageContainer>
            <MainServiceContainer>
              <TitleDescriptionContainer>
                <Title>온라인예배</Title>
                <MainServiceDescription>
                  {``}
                </MainServiceDescription>
              </TitleDescriptionContainer>
              <ServiceDetailContainer>
                <DescriptionDetailTitleMobile>
                  <span style={DescriptionTitleFont}>{`주일예배 `}</span>
                  {`1:30pm Sun`}
                </DescriptionDetailTitleMobile>
                <ServiceDetailVideoContainer>
                  <PlayerWrapper>
                      <Player
                        className='react-player'
                        url={`https://youtu.be/${upcomingSermonVideoId}?autoplay=0&control=1&color=white`}
                        controls={true}
                        width='100%'
                        height='100%'
                      />
                  </PlayerWrapper>
                </ServiceDetailVideoContainer>
                <DescriptionDetailContainer>
                  <DescriptionDetailTitle>
                    <span style={DescriptionTitleFont}>{`주일예배`}</span>{` 1:30pm Sun`}
                  </DescriptionDetailTitle>
                  {displayBox ? (
                    <TimeRemaingingContainer>
                      {displayCounter ? (
                        <>
                          <TimeRemainingTitle>다음 예배시간까지</TimeRemainingTitle>
                          <TimeRemaing>
                            {formattedTime.days && (
                              <>
                                <span style={remainingFontSize}>
                                  {formattedTime.days}
                                </span>
                                <span style={remainingUnitFontSize}>days</span>
                              </>
                            )}
                            {formattedTime.hours && (
                              <>
                                <span style={remainingFontSize}>
                                  {formattedTime.hours}
                                </span>
                                <span style={remainingUnitFontSize}>hrs</span>
                              </>
                            )}
                            {formattedTime.minutes && (
                              <>
                                <span style={remainingFontSize}>
                                  {formattedTime.minutes}
                                </span>
                                <span style={remainingUnitFontSize}>min</span>
                              </>
                            )}
                            {formattedTime.seconds && (
                              <>
                                <span style={remainingFontSize}>
                                  {formattedTime.seconds}
                                </span>
                                <span style={remainingUnitFontSize}>sec</span>
                              </>
                            )}
                          </TimeRemaing>
                        </>
                      ) : (
                        <TimeUp> {`온라인예배가 시작됩니다.`} </TimeUp>
                      )}
                    </TimeRemaingingContainer>
                  ) : null}
                  <DescriptionDetailLinksContainer>
                    <DescriptionDetailLinksExternal
                      href="https://www.youtube.com/user/joyfullchurch"
                      target="_blank"
                    >
                      {`Youtube 채널 바로가기 >`}
                    </DescriptionDetailLinksExternal>
                    {/* <DescriptionDetailLinks to="/sermons-all">
                      {`모든 설교보기 >`}
                    </DescriptionDetailLinks> */}
                  </DescriptionDetailLinksContainer>
                  <OnlineDonation>
                    {`헌금을 드리려는 분들은 `}
                    <OnlineDonationLink to="/offering/">
                      {`온라인 헌금 페이지`}
                    </OnlineDonationLink>
                    {`를 확인해주세요.`}
                  </OnlineDonation>
                </DescriptionDetailContainer>
              </ServiceDetailContainer>
            </MainServiceContainer>
          </PageContainer>
        )
      }}
    />
  )
}

export default OnlineService
